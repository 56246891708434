<template>
 <div>
   <!-- 导航栏 -->
   <div id="app">
     <Ztl />
   </div>

   <!-- 新增图片展示区 -->
   <div class="image-gallery">
     <img src="../assets/lxwm/lxwm.png" alt="Image 1 Description">
   </div>
   <!-- 商务合作 -->
   <div class="zzzs-section">
     <h2>
       <strong class="shangwuhezhuo">
         <span class="title-text">商务</span>
         <span class="highlighted-text">合作</span>
       </strong>
     </h2>
     <h2>
       <span class="highlighted-text1">---------- BUSINESS COOPERATION ----------</span>
     </h2>
   </div>

   <div class="contact-container">
     <div class="contact-info">
       <p class="icon_left">
         <b>全国统一咨询热线：</b>**********&emsp;**********
       </p>
       <p class="icon_left">
         <b>官方邮箱：</b>592376733@qq.com
       </p>
       <p class="icon_left">
         <b>QQ咨询：</b>
         <a target="_blank" href="https://wpa.qq.com/msgrd?v=3&uin=592376733&site=qq&menu=yes">592376733</a>
       </p>
       <p class="icon_left">
         <b>地址：</b>四川省成都市双流区洲际华府广场
       </p>
     </div>

     <div class="contact-info phone">
       <b class="icon_left" style="font-size: 16px;">商务咨询</b>
       <p class="icon_left" style="font-size: 16px; margin-top: 20px;">
         <b>188 1923 0391 (微信同号)</b>
       </p>
     </div>
   </div>


   <!-- 合作流程 -->
   <div class="zzzs-section">
     <h2>
       <strong class="hezuoliuchen">
         <span class="title-text">合作</span>
         <span class="highlighted-text">流程</span>
       </strong>
     </h2>
     <h2>
       <span class="highlighted-text1">---------- COOPERATION PROCESS ----------</span>
     </h2>
   </div>
   <!-- 合作流程 -->
   <div class="youshi" >    <!--:style="{ backgroundImage: `url(${backgroundImage})` }"-->
     <ul class="youshi-list">
       <li class="youshi-item" v-for="(advantage, index) in formattedAdvantages" :key="index">
         <div class="item-content">
           <img :src="advantage.image" :alt="advantage.title">
           <p>{{ advantage.title }}</p>
           <span v-html="advantage.description"></span> <!-- 使用v-html渲染包含<br>的描述 -->
         </div>
       </li>
     </ul>
   </div>

   <!-- 底部栏 -->
   <div id="app">
     <Footer />
   </div>
 </div>

</template>

<script>
import Ztl from "@/components/Ztl.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Lianxiwomen",
  components: {
    Footer,
    Ztl,

  },
  computed: {
    formattedAdvantages() {
      return this.advantages.map(advantage => ({
        ...advantage,
        description: advantage.description.replace(/\n/g, '<br>')
      }));
    }
  },
  data() {
    return {
      advantages: [
        {
          title: '整理需求',
          description: '结合企业及所属行业情况，\n' +
              '提出委托需求',
          image: require('@/assets/lxwm/lc1.png'),
        },
        {
          title: '方案初定',
          description: '根据需求评估定位效果，\n' +
              '初步提出整体方案',
          image: require('@/assets/lxwm/lc2.png'),
        },
        // 添加更多板块...
        {
          title: '现场考察',
          description: '前往实地进行考察，\n' +
              '商谈项目合作细节',
          image: require('@/assets/lxwm/lc3.png'),
        },
        {
          title: '商务对接',
          description: '敲定具体合作细节，\n' +
              '签订合同',
          image: require('@/assets/lxwm/lc4.png'),
        },
        {
          title: '项目实施',
          description: '技术人员全程指导，\n' +
              '协助完成整套系统搭建',
          image: require('@/assets/lxwm/lc5.png'),
        },
        {
          title: '售后服务',
          description: '提供后续软硬件支持，\n' +
              '24小时随时为你服务',
          image: require('@/assets/lxwm/lc6.png'),
        },
      ],
     // backgroundImage: require('@/assets/hsbjt.jpg'), // 整体背景图片路径
    }
  },
  created() {

  },
  methods: {}
}
</script>

<style scoped>
.zzzs-section {
  text-align: center;
}
.title-text {
  color: #f64a4a;
  font-size: 35px;
}
.highlighted-text1 {
  color: #4a83fd;
  font-size: 20px;
}
.highlighted-text {
  color: #4a83fd;
  font-size: 35px;
}

/* 添加一个容器类，用于居中显示 */
.contact-container {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: flex-start; /* 垂直对齐开始位置，因为可能高度不同 */
}
/* 为商务咨询模块添加现代风格的边框 */
.contact-info.phone {
  border: 1px solid #faf9f9; /* 深灰色边框 */
  border-radius: 5px; /* 圆角 */
  padding: 45px; /* 内边距，使内容与边框之间有空隙 */
  box-shadow: 0 6px 10px rgb(192, 192, 192); /* 更大的阴影效果，颜色更深 */
}

/* 为商务咨询模块下方添加虚线 */
.contact-info.phone .icon_left:first-child::after {
  content: ""; /* 必须声明content属性 */
  display: block; /* 使伪元素成为一个块级元素 */
  height: 1px; /* 虚线的高度 */
  margin-top: 5px; /* 虚线与上方文本的距离 */
  margin-bottom: 5px; /* 虚线与下方文本的距离 */
  border-bottom: 1px dashed #747574; /* 创建虚线，颜色与边框颜色一致 */
}


/* 定义一个通用的样式类，用于并排显示 */
.contact-info {
  display: inline-block;
  vertical-align: top; /* 确保垂直对齐顶部 */
  margin-right: 100px;
}
/* 改变标题的字体颜色为蓝色 */
.icon_left b,a {
  color: blue;
}
/* 为描述之间的段落添加间距 */
.icon_left {
  margin-bottom: 30px; /* 根据需要调整间距大小 */
}




.youshi {
  display: flex;
  justify-content: center;
  align-items: stretch; /* 改为stretch，使.youshi-item占据.youshi的高度 */
  position: relative;
  height: fit-content; /* 自动适应内容高度 */
  background-size: cover;
  background-position: center;
}

.youshi-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.youshi-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative; /* 添加相对定位，确保内容在背景之上 */
  z-index: 1; /* 设置较高的z-index，确保内容在背景之上 */
  width: calc(33.33% - 100px); /* 计算每列的宽度，留出间距 */
  margin-bottom: 20px;
  padding: 20px;
  transition: all 0.3s ease; /* 添加过渡效果，使所有变化平滑 */
}

.youshi-item img {
  max-width: 100%;
  height: auto;
}

.youshi-item p {
  font-size: 25px; /* 增加字体大小 */
  margin: 10px 0;
  color: #041728; /* 设置title文字颜色 */
}
.youshi-item span {
  color: #041728; /* 设置title和description文字颜色 */
}
.youshi-item:hover p {
  color: #f62222; /* 鼠标悬停时的文字颜色 */
}
/* 为每个功能模块添加现代风格的边框和白色背景 */
.youshi-item {
  border: 1px solid #616261; /* 深灰色边框 */
  border-radius: 5px; /* 圆角 */
  padding: 30px; /* 内边距，使内容与边框之间有空隙 */
  background-color: #f7f9fd; /* 设置背景颜色为白色 */
  transition: all 0.3s ease; /* 平滑过渡效果 */
}

</style>